import { ReactElement, useState } from 'react'
import { TextField, MenuItem, Button } from '@mui/material'
import Logger from '../../utils/Logger'
import './index.sass'
import { validateEmail, validatePhone } from '../../utils/strings'
import api from '../../api'
import { useDispatch } from 'react-redux'
import { showAlert } from '../../state/alert/actions'

interface ContactProps {
  service?: string
}

export default function Contact(props: ContactProps): ReactElement {
  Logger.debug('rendering Contact')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [service, setService] = useState(props.service ?? '')
  if (service === '' && props.service != null) {
    setService(props.service)
  }
  const [message, setMessage] = useState('')
  const [error, setError] = useState(new Set<string>())
  const [submittedForm, setSubmittedForm] = useState(false)
  const dispatch = useDispatch()

  function failedSubmit() {
    dispatch(
      showAlert("Oops! something went wrong and your message didn't reach us. Please try again or call 857-269-8018")
    )
  }

  function handleSubmit(event: React.SyntheticEvent) {
    event.preventDefault()
    if (!validateForm()) {
      return
    }

    api
      .addContactMessage(dispatch, name, email, phone, service, message)
      .then((response) => {
        if (!response.success) {
          failedSubmit()
        }
      })
      .catch(failedSubmit)

    setSubmittedForm(true)
    setTimeout(() => {
      setSubmittedForm(false)
    }, 5000)
  }

  function validateForm(): boolean {
    const error = new Set<string>()
    if (name === '') {
      error.add('name')
    }

    if (!validateEmail(email)) {
      error.add('email')
      error.add('email-invalid')
    }

    if (!validatePhone(phone)) {
      error.add('phone')
      error.add('phone-invalid')
    }

    if (email === '' && phone === '') {
      error.add('email')
      error.add('phone')
    }

    if (service === '') {
      error.add('service')
    }

    setError(error)
    return error.size === 0
  }

  return (
    <div id="contact">
      <h1>Reach Out</h1>
      <div className="contact-details">
        <div>
          <strong>Tel:&nbsp;</strong>
          <a href="tel:8572698018">857-269-8018</a>
        </div>
        <div className="divider">|</div>
        <div>
          <strong>Email:&nbsp;</strong>
          <a href="mailto:info@terracotta.house">info@terracotta.house</a>
        </div>
      </div>
      <form className="content" noValidate onSubmit={handleSubmit}>
        <TextField
          className="form-name"
          label="Name"
          color="secondary"
          required
          error={error.has('name')}
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <TextField
          className="form-email"
          label="Email"
          color="secondary"
          error={error.has('email')}
          helperText={
            error.has('email-invalid')
              ? 'Please enter a valid email address'
              : error.has('email')
              ? 'Email or Phone number missing'
              : ''
          }
          required={phone === ''}
          autoComplete="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <TextField
          className="form-phone"
          label="Phone"
          color="secondary"
          error={error.has('phone')}
          helperText={
            error.has('phone-invalid')
              ? 'Please enter a valid phone number'
              : error.has('phone')
              ? 'Email or Phone number missing'
              : ''
          }
          required={email === ''}
          autoComplete="tel"
          type="tel"
          value={phone}
          onChange={(event) => setPhone(event.target.value)}
        />
        <TextField
          className="form-service"
          select
          label="Select a service"
          error={error.has('service')}
          color="secondary"
          required
          value={service}
          onChange={(event) => setService(event.target.value)}
        >
          <MenuItem value={'No reno just deco'}>No reno just deco</MenuItem>
          <MenuItem value={'Face lift'}>Face lift</MenuItem>
          <MenuItem value={'Short-term rental styling'}>Short-term rental styling</MenuItem>
          <MenuItem value={'Staging and organizing'}>Staging and organizing</MenuItem>
          <MenuItem value={'Complimentary consultation'}>Complimentary consultation</MenuItem>
        </TextField>

        <TextField
          className="form-message"
          label="Tell us about your project"
          color="secondary"
          multiline
          value={message}
          onChange={(event) => setMessage(event.target.value)}
        />

        <Button className="form-button" color="secondary" type="submit" disabled={submittedForm}>
          {submittedForm ? 'Thank you!' : 'Submit'}
        </Button>
        {/* <div>
          <strong>Tel:&nbsp;</strong>857-269-8018
        </div>
        <div className="divider">|</div>
        <div>
          <strong>Email:&nbsp;</strong>michal@terracotta.homes
        </div> */}
      </form>
      <div className="banner" />
    </div>
  )
}

// import { ReactElement } from 'react'
// import { useForm } from 'react-hook-form'
// import { TextField, MenuItem, Button } from '@mui/material'
// import Logger from '../../utils/Logger'
// import './index.sass'

// export default function Contact(): ReactElement {
//   Logger.debug('rendering Contact')
//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//   } = useForm()
//   const onSubmit = (data: any) => console.log(data)
//   console.log(errors)

//   // const [name, setName] = useState('')
//   // const [email, setEmail] = useState('')
//   // const [service, setService] = useState('')
//   // const [message, setMessage] = useState('')

//   // function handleSubmit2() {
//   //   if (name === '' || email === '') {
//   //     return
//   //   }

//   //   Logger.debug(`submit: ${name}, ${email}, ${service}, ${message}`)
//   // }

//   return (
//     <div id="contact">
//       <h1>Contact</h1>
//       <form className="content" onSubmit={handleSubmit(onSubmit)}>
//         <TextField
//           {...register('name', { required: true, maxLength: 80 })}
//           className="form-name"
//           label="Name"
//           color="secondary"
//           required
//         />
//         <TextField
//           {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
//           className="form-email"
//           label="Email"
//           color="secondary"
//           autoComplete="email"
//           required
//         />
//         <TextField
//           {...register('phone', { minLength: 6, maxLength: 12 })}
//           className="form-phone"
//           label="Phone"
//           color="secondary"
//           type="tel"
//         />
//         <TextField
//           {...register('service', { required: true })}
//           select
//           label="Select a service"
//           color="secondary"
//           // helperText="Please select a service"
//         >
//           <MenuItem value={'service a'}>Service A</MenuItem>
//           <MenuItem value={'service b'}>Service B</MenuItem>
//           <MenuItem value={'service c'}>Service C</MenuItem>
//         </TextField>

//         <TextField {...register('message', {})} className="form-message" label="Message" color="secondary" multiline />

//         <Button className="form-button" color="secondary" type="submit">
//           Submit
//         </Button>
//         {/* <div>
//           <strong>Tel:&nbsp;</strong>857-269-8018
//         </div>
//         <div className="divider">|</div>
//         <div>
//           <strong>Email:&nbsp;</strong>michal@terracotta.homes
//         </div> */}
//       </form>
//       <div className="banner" />
//     </div>
//   )
// }

import { ReactElement, useEffect, useState } from 'react'
import Logger from '../../utils/Logger'
import './index.sass'

export default function Testimonials(): ReactElement {
  const NUMBER_OF_TESTIMONIALS = 4
  const DURATION_ON_SCREEN_MILLIS = 5000
  const [selected, setSelected] = useState(1)
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>()

  const stopAutoScroll = () => {
    clearInterval(intervalId)
  }

  const startAutoScroll = () => {
    const newIntervalId = setInterval(() => {
      setSelected((selected) => (selected % NUMBER_OF_TESTIMONIALS) + 1)
    }, DURATION_ON_SCREEN_MILLIS)

    setIntervalId(newIntervalId)

    return newIntervalId
  }

  useEffect(() => {
    const newIntervalId = startAutoScroll()
    return () => {
      clearInterval(newIntervalId)
    }
  }, [setIntervalId])

  Logger.debug('rendering Testimonials')
  return (
    <div id="testimonials">
      <h1>Testimonials</h1>
      <div className={`content selected-${selected}`} onMouseOver={stopAutoScroll} onMouseOut={startAutoScroll}>
        <div>
          &quot;We worked with Michal on two projects. Michal is very budget oriented and kept us where we wanted to be
          with no unnecessary costs. Although she had our budget in mind, she was still able to provide a creative
          design. She was very accessible during the process and always was available to answer our questions.&quot;
          Matthew
        </div>
        <div>
          &quot;Michal had the best ideas for our living room and dining area. We wanted a modern look with bold colors,
          Michal made sure we would get it and more. Everything came together in a beautiful harmony of colors and
          materials. Thanks to her, decorating our new house felt like effortless work.&quot; Yulia
        </div>
        <div>
          &quot;Michal helped us create the best layout for our family. She showed us different alternatives and
          delivered a cozy and multifunctional design for our home.&quot; Uliana
        </div>
        <div>
          &quot;We needed a more organized and bright playroom for our kids. Michal helped us get what we wanted in a
          quick and easy process. The room was completely transformed, including floors, paint and decor and we
          couldn&apos;t be more happy with the results.&quot; Asif
        </div>
      </div>
    </div>
  )
}

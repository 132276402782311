import AppStage from '../state/app/AppStage'

export const KEY_OFFER_SHOWN = 'offer_shown'

export const APP_STAGE = AppStage.NORMAL
export const SMALL_SCREEN_SIZE = 1150

export const SHOW_NAVIGATION_ARROWS = true

export const SHOW_OFFER = true
export const OFFER_DELAY_MILLIS = 1500

export default {}

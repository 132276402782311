import { ReactElement, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Routes, Route, Link as RouterLink, useLocation } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import Link from '@mui/material/Link'
import IconButton from '@mui/material/IconButton'
import Drawer, { DrawerProps } from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import styled from '@emotion/styled'
import { SMALL_SCREEN_SIZE } from '../../utils/constants'
import { closeAlert } from '../../state/alert/actions'
import { selectAlert } from '../../state/alert/state'
import { scrollOffset } from '../../utils/utils'
import Home from '../Home'
// import Toc from '../Toc'
import Modal from '../../components/Modal_V2'
import Spacer from '../../components/Spacer'
import logo from '../../assets/logo.png'
import whiteLogo from '../../assets/logo-white.png'
import './index.sass'
import { SvgIconProps } from '@mui/material'

export default function App(): ReactElement {
  const smallScreen = useMediaQuery(`(max-width: ${SMALL_SCREEN_SIZE}px)`)
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | undefined>()
  const menuOpen = Boolean(menuAnchor)

  const alert = useSelector(selectAlert)
  const dispatch = useDispatch()
  const location = useLocation()

  const [showMenuBackground /*, setShowMenuBackground*/] = useState(false)
  const [isSticky, setIsSticky] = useState(false)
  useScrollPosition(({ prevPos, currPos }) => {
    // setShowMenuBackground(currPos.y < -70)
    setIsSticky(currPos.y < 0)
  })

  function handleAlertClose(event: any, reason?: string) {
    if (reason === 'clickaway') return
    dispatch(closeAlert())
  }

  function openMenu(event: React.MouseEvent<HTMLElement>) {
    setMenuAnchor(event.currentTarget)
  }

  function closeMenu(event: any) {
    setMenuAnchor(undefined)
  }

  const renderRouterPaths = useMemo(
    () => (
      <Routes>
        {/* <Route path="/toc" element={<Toc />} /> */}
        <Route path="/" element={<Home />} />
      </Routes>
    ),
    []
  )

  return (
    <div id="app">
      <header className={isSticky ? 'sticky' : ''}>
        <section>
          <img className="logo" src={isSticky ? logo : whiteLogo} alt="Terracotta logo" />
          <>
            {location.pathname !== '/' ? (
              <Link component={RouterLink} to="/" color="inherit" style={{ display: smallScreen ? 'none' : '' }}>
                Home
              </Link>
            ) : (
              <></>
            )}
            <Link
              component={HashLinkThemed}
              to="/#the-studio"
              color="inherit"
              smooth
              scroll={scrollOffset}
              style={{ display: smallScreen ? 'none' : '' }}
            >
              The Studio
            </Link>
            <Link
              component={HashLinkThemed}
              to="/#services"
              color="inherit"
              smooth
              scroll={scrollOffset}
              style={{ display: smallScreen ? 'none' : '' }}
            >
              Services
            </Link>
            <Link
              component={HashLinkThemed}
              to="/#projects"
              color="inherit"
              smooth
              scroll={scrollOffset}
              style={{ display: smallScreen ? 'none' : '' }}
            >
              Projects
            </Link>
            <Link
              component={HashLinkThemed}
              to="/#contact"
              color="inherit"
              smooth
              scroll={scrollOffset}
              style={{ display: smallScreen ? 'none' : '' }}
            >
              {'Reach Out'}
            </Link>
          </>
        </section>
        <Spacer />
        <a href="tel:8572698018" className={`contact-phone ${!isSticky ? 'hidden' : ''}`}>
          857-269-8018
        </a>
        {smallScreen && (
          <>
            <div style={{ width: '60px' }} />
            <section
              style={{
                position: 'fixed',
                right: '16px',
                transition: showMenuBackground ? '0.2s ease-in' : '0.2s ease-out',
                background: showMenuBackground ? 'var(--menu-button-bg-color)' : '',
                boxShadow: showMenuBackground ? 'var(--menu-button-bg-shadow)' : '',
                borderRadius: showMenuBackground ? '100%' : '',
              }}
            >
              <IconButton aria-label="more" aria-controls="overflow-menu" aria-haspopup="true" onClick={openMenu}>
                <MenuIconThemed isSticky={isSticky} />
              </IconButton>
              <DrawerThemed smallScreen={smallScreen} anchor="right" open={menuOpen} onClose={closeMenu}>
                <>
                  <IconButton
                    aria-label="close"
                    aria-controls="close-overflow-menu"
                    aria-haspopup="true"
                    style={{
                      width: '60px',
                      height: '60px',
                      marginRight: '16px',
                      marginTop: '16px',
                    }}
                    onClick={closeMenu}
                  >
                    <CloseIcon />
                  </IconButton>
                  <List>
                    <ListItem button key="home" component={HashLinkThemed} to="/#" smooth onClick={closeMenu}>
                      <ListItemText primary={'Home'} />
                    </ListItem>
                    <ListItem
                      key="the-studio"
                      component={HashLinkThemed}
                      to="/#the-studio"
                      scroll={scrollOffset}
                      smooth
                      onClick={closeMenu}
                    >
                      <ListItemText primary={'The Studio'} />
                    </ListItem>
                    <ListItem
                      key="services"
                      component={HashLinkThemed}
                      to="/#services"
                      smooth
                      scroll={scrollOffset}
                      onClick={closeMenu}
                    >
                      <ListItemText primary={'Services'} />
                    </ListItem>
                    <ListItem
                      key="projects"
                      component={HashLinkThemed}
                      to="/#projects-mobile"
                      smooth
                      scroll={scrollOffset}
                      onClick={closeMenu}
                    >
                      <ListItemText primary={'Projects'} />
                    </ListItem>
                    <ListItem
                      key="contact"
                      component={HashLinkThemed}
                      to="/#contact"
                      smooth
                      scroll={scrollOffset}
                      onClick={closeMenu}
                    >
                      <ListItemText primary={'Reach Out'} />
                    </ListItem>
                  </List>
                </>
              </DrawerThemed>
            </section>
          </>
        )}
      </header>
      <div className="content">{renderRouterPaths}</div>
      <footer>
        <div>Copyright © Terracotta Studio</div>
        {/* <div>
          <a href="/toc">Terms & Conditions</a>
        </div> */}
      </footer>
      <Snackbar
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        open={alert.open}
        autoHideDuration={alert.autoHideDurationMs}
        onClose={handleAlertClose}
      >
        <Alert severity="warning">{alert.message}</Alert>
      </Snackbar>
      <Modal />
    </div>
  )
}

const HashLinkThemed = styled(HashLink)`
  color: var(--secondary-color) !important;
  transition: color var(--transition-color);
  &:hover {
    color: var(--secondary-color-hover) !important;
  }
`

interface MenuIconThemedProps extends SvgIconProps {
  isSticky: boolean
}

const MenuIconThemed = styled(({ isSticky, ...props }: MenuIconThemedProps) => (
  <MenuIcon {...props} />
))<MenuIconThemedProps>`
  fill: ${({ isSticky }) => (isSticky ? 'var(--secondary-color)' : 'white')} !important;
  transition: fill var(--transition-color);
  width: 1.5em !important;
  height: 1.5em !important;

  ₪:hover {
    fill: var(--secondary-color-hover) !important;
  }
`

interface DrawerThemedProps extends DrawerProps {
  smallScreen: boolean
}

const DrawerThemed = styled(({ smallScreen, ...props }: DrawerThemedProps) => <Drawer {...props} />)<DrawerThemedProps>`
  > div:nth-last-of-type(2) {
    width: ${({ smallScreen }) => (smallScreen ? '80%' : '30%')};
    color: var(--secondary-color);
    background-color: var(--theme-color);

    button {
      width: 50px;
      height: 50px;
      margin-left: auto;

      svg {
        width: 36px;
        height: 36px;
        fill: var(--secondary-color);
        transition: fill var(--transition-color);
      }

      svg:hover {
        fill: var(--secondary-color-hover);
      }
    }

    ul * span {
      font-family: var(--header-font) !important;
      font-size: 2em !important;
      text-align: center;
    }

    a:hover {
      color: var(--secondary-color-hover);
    }
  }
`

import { ReactElement } from 'react'
import { HashLink } from 'react-router-hash-link'
import Link from '@mui/material/Link'
import Logger from '../../utils/Logger'
import { scrollOffset } from '../../utils/utils'
import './index.sass'

export default function About(): ReactElement {
  Logger.debug('rendering About')

  return (
    <div id="about">
      <h1>Meet The Designer</h1>
      <div className="wrapper">
        <div className="profile" />
        <div className="content">
          <section className="about-me">
            {/* <h3>Meet the designer</h3> */}
            <p>
              Hi, my name is Michal Lazarovich and my passion is to design houses. All my life I enjoyed art, design and
              aesthetics and from a very young age I helped my family and friends design their living spaces. I believe
              interior design is for everyone! Even with a limited budget, you can accomplish high-end results. Every
              house I design sparks me with a new inspiration. I&apos;m always excited to meet new clients and work
              together to make their home personal and special for them.{' '}
              <Link component={HashLink} to="/#contact" color="inherit" smooth scroll={scrollOffset}>
                <strong>Let&apos;s talk about your project!</strong>
              </Link>
            </p>
          </section>
        </div>
      </div>
    </div>
  )
}

import { ReactElement, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { SHOW_OFFER, OFFER_DELAY_MILLIS, KEY_OFFER_SHOWN } from '../../utils/constants'
import { ModalType } from '../../state/modal/state'
import { openModal } from '../../state/modal/actions'
import Logger from '../../utils/Logger'
import iconDecoration from '../../assets/icons/decoration.png'
import iconFacelift from '../../assets/icons/facelift.png'
import iconRentals from '../../assets/icons/rentals.png'
import iconStaging from '../../assets/icons/staging.png'
import iconComplimentary from '../../assets/icons/complimentary.png'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import './index.sass'

export default function Services(): ReactElement {
  Logger.debug('rendering Services')
  const [showOffer, setShowOffer] = useState(false)
  const [offerShown, setOfferShown] = useLocalStorage(KEY_OFFER_SHOWN, false)
  const serviceRef = useRef<HTMLElement>(null)
  const dispatch = useDispatch()

  useScrollPosition(({ prevPos, currPos }) => {
    if (serviceRef?.current != null) {
      const serviceVisible =
        serviceRef.current.getBoundingClientRect().bottom + 20 > 0 &&
        serviceRef.current.getBoundingClientRect().bottom + 20 < window.innerHeight
      if (SHOW_OFFER && serviceVisible && !showOffer && !offerShown) {
        setShowOffer(true)
        setOfferShown(true)
        setTimeout(() => {
          dispatch(openModal(ModalType.OFFER))
        }, OFFER_DELAY_MILLIS)
      }
    }
  })

  return (
    <div id="services">
      <h1>Services</h1>
      <div className="rows">
        <div className="cover" />
        <div className="content">
          <section className="service">
            <img src={iconDecoration} />
            <h3>No reno just deco</h3>
            <Divider />
            <p>
              We can do a lot just by creating a new furniture floor plan by mixing up old and new furniture, light
              fixtures, accessories and paint. This service is perfect when no renovation is needed but also for
              repurposing a room or a space.
            </p>
          </section>
          <section className="service">
            <img src={iconFacelift} />
            <h3>Face lift</h3>
            <Divider />
            <p>
              A complete transformation when renovating. This service can be focused on floors, kitchen cabinets, tiles,
              vanities and more.
            </p>
          </section>
          <section className="service">
            <img src={iconRentals} />
            <h3>Short-term rental styling</h3>
            <Divider />
            <p>
              We will make sure your short-term rental property is ready to go with an appealing design that will make
              it pop and standout!
            </p>
          </section>
          <section className="service">
            <img src={iconStaging} />
            <h3>Staging and organizing</h3>
            <Divider />
            <p>
              Selling a house can be stressful. With our service, we will decluttered the house, stage and prepare it
              for photos and an easy sale.
            </p>
          </section>
          <section className="service" ref={serviceRef}>
            <img src={iconComplimentary} />
            <h3>Complimentary Consultation</h3>
            <Divider />
            <p>Create a list of ideas for renovation, design and floor plan.</p>
          </section>
        </div>

        {/* <div className="wrapper">
          <div className="interior-design" />
          <div className="home-decor" />
        </div> */}
      </div>
    </div>
  )
}

const Divider = () => (
  <div className="divider">
    <span className="divider-separator"></span>
  </div>
)

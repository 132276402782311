import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import smoothscroll from 'smoothscroll-polyfill'
import reportWebVitals from './reportWebVitals'
import App from './pages/App'
import ScrollToTop from './components/ScrollToTop'
import store from './state'
import './index.sass'

// kick off the polyfill!
smoothscroll.polyfill()

const theme = createTheme({
  palette: {
    secondary: {
      main: '#C7A472',
    },
  },
})

render(
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </ScrollToTop>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)

if (process.env.NODE_ENV === 'development') {
  reportWebVitals(console.log)
}

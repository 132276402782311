import { configureStore } from '@reduxjs/toolkit'
import modal from './modal/reducer'
import alert from './alert/reducer'
import app from './app/reducer'
import { ModalState } from './modal/state'
import { AlertState } from './alert/state'
import { AppState } from './app/state'

const store = configureStore({
  reducer: {
    modal,
    alert,
    app,
  },
})

export interface Store {
  modal: ModalState
  alert: AlertState
  app: AppState
}

export default store

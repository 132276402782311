import { useState, useEffect } from 'react'

export const getStorageValue = (key: string, defaultValue: unknown): any => {
  // getting stored value
  const saved = localStorage.getItem(key)
  if (saved == null) {
    return defaultValue
  } else {
    const expireDate = localStorage.getItem(expiration(key))
    if (expireDate != null && Number.parseInt(expireDate) < Date.now()) {
      // Value expired
      localStorage.removeItem(key)
      return defaultValue
    }
    const initial = JSON.parse(saved)
    return initial || defaultValue
  }
}

export const useLocalStorage = (key: string, defaultValue: unknown): any => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue)
  })

  useEffect(() => {
    // storing input name
    localStorage.setItem(key, JSON.stringify(value))
    localStorage.setItem(expiration(key), (Date.now() + EXPIRATION_TIME_MILLIS).toString())
  }, [key, value])

  return [value, setValue]
}

const EXPIRATION_TIME_MILLIS = 6.048e8 // 1 week
const expiration = (key: string): string => `${key}_expire`

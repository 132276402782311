import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal, openModal } from '../../state/modal/actions'
import { Backdrop, Fade, Modal as MuiModal, TextField, Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import FileUpload from 'react-mui-fileuploader'
import { selectModal } from '../../state/modal/state'
import { validateEmail } from '../../utils/strings'
import api from '../../api'
import { showAlert } from '../../state/alert/actions'
import './index.sass'

export default function Modal(): ReactElement {
  const [email, setEmail] = useState('')
  const [error, setError] = useState(new Set<string>())
  const [files, setFiles] = useState<any[]>([])
  const modalState = useSelector(selectModal)
  const dispatch = useDispatch()

  function failedSubmit() {
    dispatch(
      showAlert("Oops! something went wrong and your message didn't reach us. Please try again or call 857-269-8018")
    )
    dispatch(openModal(modalState.type))
  }

  async function handleSubmit(event: React.SyntheticEvent) {
    event.preventDefault()
    if (!validateForm()) {
      return
    }

    let responsePromise
    if (files.length > 0) {
      const attachmentRaw = await fetch(files[0].path)
      const attachmentBlob = await attachmentRaw.blob()
      const file = new File([attachmentBlob], 'attachment.' + files[0].extension)
      console.log(file)
      responsePromise = api.addContactMessage(dispatch, email, email, '', 'holiday special', '', file)
    } else {
      responsePromise = api.addContactMessage(dispatch, email, email, '', 'holiday special', '')
    }

    responsePromise
      .then((response) => {
        if (!response.success) {
          failedSubmit()
        }
      })
      .catch(failedSubmit)

    dispatch(closeModal())
  }

  function validateForm(): boolean {
    const error = new Set<string>()

    if (email === '') {
      error.add('email')
    }

    if (!validateEmail(email)) {
      error.add('email')
      error.add('email-invalid')
    }

    setError(error)
    return error.size === 0
  }

  const handleFileUploadError = (error: any) => {
    showAlert("Oops! something went wrong and your message didn't reach us. Please try again or call 857-269-8018")
  }

  const handleFilesChange = (files: any) => {
    setFiles(files)
  }

  return (
    <MuiModal
      className="modal"
      open={modalState.open}
      onClose={() => dispatch(closeModal())}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 250,
      }}
    >
      <Fade in={modalState.open}>
        <div className="modal-wrapper">
          <CloseIcon className="close" onClick={() => dispatch(closeModal())} />
          <div className="columns">
            <div className="cover" />
            <div className="content">
              <h2>Holiday Special</h2>
              <p>
                Send us a picture of your space or just leave us your email to get a{' '}
                <strong>free 30 min consultation!</strong>
              </p>
              <FileUpload
                title=""
                multiFile={false}
                disabled={false}
                header=""
                leftLabel=""
                rightLabel=""
                buttonLabel="Upload File"
                buttonRemoveLabel="Remove all"
                maxFileSize={3}
                maxUploadFiles={1}
                maxFilesContainerHeight={100}
                errorSizeMessage={'Please upload files less than 3mb'}
                allowedExtensions={['jpg', 'jpeg', 'png']}
                onFilesChange={handleFilesChange}
                onError={handleFileUploadError}
                // imageSrc={''}
                bannerProps={{
                  elevation: 0,
                  variant: '',
                  style: { backgroundColor: 'var(--primary-color-alpha)' },
                }}
                containerProps={{ elevation: 0, variant: '' }}
              />
              <form className="contact-form" noValidate onSubmit={handleSubmit}>
                <TextField
                  className="form-email"
                  label="Email"
                  color="secondary"
                  error={error.has('email')}
                  helperText={error.has('email-invalid') ? 'Please enter a valid email address' : ''}
                  required
                  autoComplete="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />

                <Button className="form-button" color="secondary" type="submit" disabled={email == ''}>
                  Submit
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Fade>
    </MuiModal>
  )
}

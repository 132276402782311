import { ReactElement, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import { useSwipeable } from 'react-swipeable'
// import ArrowLoopBackIcon from '@mui/icons-material/RepeatRounded'
import Logger from '../../utils/Logger'
import DotsCarousel from '../DotsCarousel'
import './index.sass'

export default function Projects(): ReactElement {
  Logger.debug('rendering Projects')
  const HIGHLIGHTED_PROJECTS = 11
  const [selected, setSelected] = useState(1)

  const handlers = useSwipeable({
    onSwipedRight: (eventData) => {
      if (selected > 1) setSelected((selected) => selected - 1)
    },
    onSwipedLeft: (eventData) => {
      if (selected < HIGHLIGHTED_PROJECTS) setSelected((selected) => selected + 1)
    },
  })
  return (
    <div id="projects-mobile">
      <h1>Recent Projects</h1>
      {/* <div className="fade-right" />
      <div className="fade-left" /> */}
      <div {...handlers} className={`highlights selected-${selected}`}>
        {[...Array(HIGHLIGHTED_PROJECTS).keys()].map((i) => (
          <div key={i} onClick={(event) => setSelected(i + 1)} />
        ))}
        <IconButton disableRipple className="loopback" onClick={(event) => setSelected(1)}>
          {/* <ArrowLoopBackIcon className="loopback" /> */}
        </IconButton>
      </div>
      <DotsCarousel
        dots={HIGHLIGHTED_PROJECTS}
        animate={true}
        selected={selected}
        onSelected={setSelected}
        onBack={(event) => setSelected((selected) => (selected === 1 ? HIGHLIGHTED_PROJECTS : selected - 1))}
        onForward={(event) => setSelected((selected) => (selected === HIGHLIGHTED_PROJECTS ? 1 : selected + 1))}
      />
    </div>
  )
}

import { ReactElement, useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { HashLink } from 'react-router-hash-link'
import Link from '@mui/material/Link'
import Logger from '../../utils/Logger'
import { scrollOffset } from '../../utils/utils'
import Projects from '../../components/Projects'
import ProjectsMobile from '../../components/Projects-mobile'
import About from '../../components/About'
import Contact from '../../components/Contact'
import Services from '../../components/Services'
import TheStudio from '../../components/TheStudio'
import Testimonials from '../../components/Testimonials'
import './index.sass'

export default function Home(): ReactElement {
  Logger.debug('rendering Home')
  const [service, setService] = useState<string>()
  const smallScreen = useMediaQuery(`(max-width: 800px)`)

  return (
    <div className="home">
      <div className="cover">
        <Link
          component={HashLink}
          to="/#contact"
          className={`contact ${!smallScreen ? 'hidden' : ''}`}
          smooth
          scroll={(el: HTMLElement) => {
            scrollOffset(el)
            setTimeout(() => {
              setService('Complimentary consultation')
            }, 250)
          }}
        >
          <span>Schedule Free Consultation</span>
        </Link>
      </div>
      <TheStudio />
      <Services />
      <Testimonials />
      {smallScreen ? <ProjectsMobile /> : <Projects />}
      <About />
      <Contact service={service} />
    </div>
  )
}

import React, { MouseEventHandler } from 'react'
import { styled as muiStyled } from '@mui/system'
import styled from '@emotion/styled'
import IconButton from '@mui/material/IconButton'
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos'
import { SHOW_NAVIGATION_ARROWS } from '../../utils/constants'

export interface DotsCarouselProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  dots: number
  animate: boolean
  selected: number
  reverse?: boolean
  onSelected: (selected: number) => void
  onBack: MouseEventHandler
  onForward: MouseEventHandler
}

export default muiStyled(
  ({ dots, animate, selected, reverse, onSelected, onBack, onForward, ...props }: DotsCarouselProps) => {
    return (
      <div {...props}>
        {SHOW_NAVIGATION_ARROWS ? (
          <>
            <NavigationArrow animate={animate} onClick={onBack}>
              <ArrowBackIcon />
            </NavigationArrow>
          </>
        ) : (
          <></>
        )}
        {[...Array(dots).keys()].map((value, index) => {
          index = reverse ? dots - 1 - index : index
          index += 1 // characters start at 1
          return (
            <div
              key={index}
              className={selected === index ? 'active' : ''}
              onClick={() => selected !== index && onSelected(index)}
            ></div>
          )
        })}
        {SHOW_NAVIGATION_ARROWS ? (
          <>
            <NavigationArrow animate={animate} onClick={onForward}>
              <ArrowForwardIcon />
            </NavigationArrow>
          </>
        ) : (
          <></>
        )}
      </div>
    )
  }
)({
  // position: 'relative',
  // bottom: '-40px',
  // left: '50%',
  // transform: 'translateX(-50%)',
  marginTop: '8px',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'row',
  placeItems: 'center',
  transition: 'opacity var(--transition-opacity)',

  '& div': {
    opacity: ({ animate }: DotsCarouselProps) => (animate ? 0.6 : 0),
    borderRadius: '100%',
    width: '5px',
    height: '5px',
    border: '1px solid var(--secondary-color)',
    boxShadow: '0 0 3px 0px var(--secondary-color)',
    transition: 'var(--transition-color)',
    cursor: 'pointer',
  },

  '& div:hover': {
    boxShadow: '0 0 5px 1px var(--secondary-color)',
  },

  '& div:not(:last-child)': {
    marginRight: '8px',
  },

  '& .active': {
    backgroundColor: 'var(--tertiary-color)',
    border: '1px solid var(--tertiary-color)',
    // border: '3px solid var(--primary-color)',
    boxShadow: '0 0 0px 1px var(--tertiary-color) !important',
  },
})

interface NavigationArrowProps {
  animate: boolean
  children: any
  onClick?: MouseEventHandler
}

const NavigationArrow = styled(({ animate, onClick, ...props }: NavigationArrowProps) => (
  <IconButton {...props} size="small" onClick={onClick}>
    {props.children}
  </IconButton>
))`
  color: var(--tertiary-color);
  transition: color var(--transition-color);
  opacity: ${({ animate }: NavigationArrowProps) => (animate ? 0.6 : 0)};

  &:hover {
    color: var(--tertiary-hover-color);
  }
`

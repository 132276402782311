import { ReactElement } from 'react'
import Logger from '../../utils/Logger'
import './index.sass'

export default function TheStudio(): ReactElement {
  Logger.debug('rendering The Studio')
  return (
    <div id="the-studio">
      <h1>Terracotta Studio</h1>
      <div className="columns">
        <div className="content">
          Welcome to Terracotta studio, we provide a unique design plan for your house. Making your house feel like home
          is our mission. Getting into the design process is an adventure, there are endless possibilities of colors,
          styles and trends to choose from. Our studio is both honored and excited to join your journey towards a
          beautiful home. Terracotta was formed in Apex NC, and so we are greatly familiar with the North Carolina
          building architecture. In addition to our in-person design services in the triangle area, our studio offers
          remote design services all over the country.
        </div>
        <div className="cover" />
      </div>
    </div>
  )
}

import { ReactElement, useState } from 'react'
import { Gallery, Image } from 'react-grid-gallery'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import Logger from '../../utils/Logger'
import './index.sass'

const HIGHLIGHTED_PROJECTS = 11
const images: Image[] = []
Array(HIGHLIGHTED_PROJECTS)
  .fill(0)
  .map((_, i) => {
    const getWidth = (i: number) => {
      switch (i) {
        case 1:
          return 401
        case 2:
          return 667
        case 5:
          return 785
        case 7:
          return 534
        case 9:
          return 356
        case 10:
          return 770
        case 11:
          return 690
        default:
          return 800
      }
    }

    images.push({
      src: `/highlights/${i + 1}.jpg`,
      width: getWidth(i + 1),
      height: 534,
    })
  })

export default function Projects(): ReactElement {
  Logger.debug('rendering Projects')
  const [index, setIndex] = useState(-1)

  const currentImage = images[index]
  const nextIndex = (index + 1) % images.length
  const nextImage = images[nextIndex] || currentImage
  const prevIndex = (index + images.length - 1) % images.length
  const prevImage = images[prevIndex] || currentImage

  const handleClick = (index: number, item: Image) => setIndex(index)
  const handleClose = () => setIndex(-1)
  const handleMovePrev = () => setIndex(prevIndex)
  const handleMoveNext = () => setIndex(nextIndex)

  return (
    <div id="projects">
      <h1>Recent Projects</h1>
      <Gallery images={images} onClick={handleClick} enableImageSelection={false} />
      {!!currentImage && (
        // @ts-ignore
        <Lightbox
          mainSrc={currentImage.src}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.src}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.src}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
    </div>
  )
}

class Logger {
  devEnvironment: boolean

  constructor() {
    this.devEnvironment = process.env.NODE_ENV === 'development'
  }

  log(message: any, ...args: any[]) {
    console.log(message, ...args)
  }

  debug(message: any, ...args: any[]) {
    if (this.devEnvironment) console.log('%c[DEBUG]', 'color:#1aa260', message, ...args)
  }

  warn(message: any, ...args: any[]) {
    console.warn(message, ...args)
  }

  error(message: any, ...args: any[]) {
    console.error(message, ...args)
  }
}

export default new Logger()

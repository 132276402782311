import { createReducer } from '@reduxjs/toolkit'
import initialState from './state'
import { closeAlert, showAlert, updateAlert } from './actions'

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateAlert, (state, action) => {
      state.open = action.payload.open
      state.message = action.payload.message
      state.autoHideDurationMs = action.payload.autoHideDurationMs ?? state.autoHideDurationMs
    })
    .addCase(showAlert, (state, action) => {
      state.open = true
      state.message = action.payload
    })
    .addCase(closeAlert, (state, action) => {
      state.open = false
      state.message = undefined
    })
)

import { Store } from '../index'

export enum ModalType {
  UNSPECIFIED,
  OFFER,
}

export interface ModalState {
  open: boolean
  type: ModalType
}

export const initialState: ModalState = {
  open: false,
  type: ModalType.UNSPECIFIED,
}

export const selectModal: (store: Store) => ModalState = (store: Store) => store.modal

export default initialState

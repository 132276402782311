/** Reduces an Address to first and last 4 hex values for brevity. */

export function minifyAddress(address: string): string {
  return address.replace(/^(.{6}).+(.{4})$/, '$1...$2')
}

export function isNumber(value: string | number | undefined): boolean {
  return value != null && value !== '' && !isNaN(Number(value.toString()))
}

export function toSentenceCase(str: string): string {
  return str
    .toLowerCase()
    .replace(/[a-z]/i, function (letter) {
      return letter.toUpperCase()
    })
    .trim()
}

export function validateEmail(email: string): boolean {
  return email === '' || /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
}

export function validatePhone(phone: string): boolean {
  return phone === '' || isNumber(phone)
}

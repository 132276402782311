import React, { useEffect } from 'react'
import { useLocation } from 'react-router'

const ScrollToTop = (props: React.PropsWithChildren<any>): React.ReactElement => {
  const { pathname } = useLocation()
  useEffect(() => {
    if (!pathname.includes('#')) {
      window.scrollTo(0, 0)
    }
  }, [pathname])

  return <>{props.children}</>
}

export default ScrollToTop

import Logger from '../utils/Logger'
import { AppStatus } from '../state/app/state'
import retry from 'async-retry'
import { Dispatch } from 'react'
import { updateAppStatus } from '../state/app/actions'

const SERVER_BASE_URI =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_LOCAL_API
      ? 'http://127.0.0.1:5001/terracotta-design/us-east4/'
      : 'https://us-east4-terracotta-design.cloudfunctions.net/'
    : 'https://us-east4-terracotta-design.cloudfunctions.net/'

const POST_ADD_CONTACT_MESSAGE = 'addContactMessage'

const UNRECOVERABLE_ERRORS = [400 /* Bad request */, 401 /* Unauthorized */, 403 /* Forbidden */, 404 /* Not found */]

interface Contract {
  addContactMessage: (
    dispatch: Dispatch<any>,
    name: string,
    email: string,
    phone: string,
    service: string,
    message: string
  ) => Promise<ServerResponse>
}

export interface ServerResponse {
  success: boolean
  message?: string
  error?: string
}

class Api implements Contract {
  addContactMessage(
    dispatch: Dispatch<any>,
    name: string,
    email: string,
    phone: string,
    service: string,
    message: string,
    file?: File
  ): Promise<ServerResponse> {
    if (Api.mocking('addContactMessage')) {
      return Promise.resolve({ success: true })
    }

    const data = new FormData()
    data.append('name', name)
    data.append('email', email)
    data.append('phone', phone)
    data.append('service', service)
    data.append('message', message)
    if (file != null) {
      data.append('file', file)
    }

    return Api.callWithRetry(dispatch, (/*bail, attempt*/) => {
      return fetch(SERVER_BASE_URI + POST_ADD_CONTACT_MESSAGE, {
        method: 'POST',
        // headers: { 'Content-Type': 'application/json' },
        body: data,
      })
    }).then((response) => response.json())
  }

  private static callWithRetry(dispatch: Dispatch<any>, params: CallParams | FuncCall): Promise<Response> {
    const fail = false
    return retry(
      async (bail: () => Promise<any>, attempt: number) => {
        if (fail) return bail()
        Api.resetAppStatus(dispatch, attempt)
        return (
          (params as CallParams).fn ? (params as CallParams).fn(bail, attempt) : (params as FuncCall)(bail, attempt)
        ).then((response) => Api.bailOnUnrecoverableError(response, bail))
        // .catch((error: Error) => {
        //   dispatch(updateAppStatus(AppStatus.NETWORK_ISSUES))
        //   Logger.debug(`Bailing due to unrecoverable server error: ${error}`)
        //   bail()
        // })
      },
      {
        retries: 5,
        onRetry: (error: Error, attempt: number) => {
          dispatch(updateAppStatus(AppStatus.NETWORK_ISSUES))
          if ((params as CallParams).onRetry !== undefined) {
            const cb = (params as CallParams).onRetry
            cb && cb(error, attempt)
          }
        },
      }
    )
  }

  private static bailOnUnrecoverableError(response: Response, bail: () => void): Response {
    Logger.debug(`Server responded with status: ${response.status}`)
    if (UNRECOVERABLE_ERRORS.includes(response.status)) {
      Logger.debug(`Bailing due to unrecoverable server error: ${response.status}`)
      bail()
    }

    return response
  }

  private static resetAppStatus(dispatch: Dispatch<any>, attempt: number) {
    return (response: any) => {
      if (attempt > 1) {
        // Previous attempts failed to get a response and the status
        // reflected that. We now need to reset the status.
        dispatch(updateAppStatus(AppStatus.GOOD))
      }

      return response
    }
  }

  private static mocking(fn: string): boolean {
    if (process.env.REACT_APP_MOCK_API === 'true') {
      Logger.warn(`Mocking response for ${fn}`)
      return true
    }
    return false
  }
}

interface CallParams {
  fn: FuncCall
  onRetry?: RetryCall
}

type FuncCall = (bail: () => void, attempt: number) => Promise<Response>
type RetryCall = (error: Error, attempt: number) => void

export default new Api()
